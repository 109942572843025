import React from 'react';

import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Container, FormContainer } from './styles';
import MaskedInput from '~/components/inputs/MaskedInput';
import PasswordInput from '~/components/inputs/PasswordInput';
import SimpleButton from '~/components/buttons/SimpleButton';
import { Creators as AuthActions } from '~/store/modules/auth/duck';

export default function Login() {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      cpf: '',
      password: '',
    },
    onSubmit: ({ cpf, password }) => {
      dispatch(AuthActions.signInRequest(cpf, password));
    },
  });

  return (
    <Container>
      <FormContainer onSubmit={formik.handleSubmit}>
        <header>BEM-VINDO</header>
        <MaskedInput
          type="tel"
          mask="999.999.999-99"
          name="cpf"
          id="cpf"
          placeholder="CPF"
          required
          onChange={formik.handleChange}
          value={formik.values.cpf}
          background="#fff"
        />
        <PasswordInput
          color="#333"
          background="#fff"
          id="password"
          placeholder="Senha"
          name="password"
          type="password"
          required
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        <SimpleButton type="submit" text="Entrar" bg="#245378" color="#fff" />
      </FormContainer>
    </Container>
  );
}
