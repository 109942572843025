import { combineReducers } from 'redux';

import auth from './auth/duck';
import alerts from './alerts/duck';
import buffer from './buffer/duck';

export default combineReducers({
  auth,
  alerts,
  buffer,
});
