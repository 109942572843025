import { trafficControl, mapControl } from './controls';

export function initControls(map, measureTool) {
  const centerControlDiv = document.createElement('div');
  new mapControl(centerControlDiv, map);

  centerControlDiv.index = 1;
  map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
    centerControlDiv
  );

  const trafficControlDiv = document.createElement('div');
  new trafficControl(trafficControlDiv, map);

  trafficControlDiv.index = 1;
  map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(
    trafficControlDiv
  );
}
