import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-awesome-modal';
import { MdContentCopy } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import SimpleButton from '~/components/buttons/SimpleButton';
import axios from '~/services/api';
import { Creators as BufferActions } from '~/store/modules/buffer/duck';

import { Container, RadioContainer, ShareUrl } from './styles';

export default function ModalContent({ tracker }) {
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const visible = useSelector((state) => state.buffer.modalShare);
  const { label, tracker_id } = tracker;
  const formik = useFormik({
    initialValues: {
      hours: 1,
    },
    onSubmit: async (values) => {
      const { data } = await axios.post('/share', {
        tracker_id,
        hours: parseInt(values.hours),
      });
      setUrl(data.url);
    },
  });
  async function getUrl() {
    const { data } = await axios.post('/share', {
      tracker_id,
      hours: parseInt(formik.values.hours),
    });
    setUrl(data.url);
  }
  function shareButton() {
    if (navigator.share) {
      navigator
        .share({
          title: 'GEORASTREAMENTO',
          url,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      console.log({ title: 'GEORASTREAMENTO', url });
      // shareDialog.classList.add('is-open');
    }
  }

  useEffect(() => {
    formik.setValues({ ...formik.values, tracker_id, password: '' });
  }, [tracker]);

  useEffect(() => {
    if (tracker_id) getUrl();
  }, [formik.values.hours]);

  useEffect(() => {
    if (tracker_id) getUrl();
  }, [tracker_id]);

  function handleClose() {
    dispatch(BufferActions.setModalShare());
  }

  function copyUrl() {
    navigator.clipboard.writeText(url).then(
      function () {
        toast.success('URL copiada para área de transferência');
      },
      function (err) {
        toast.error('Erro ao copiar URL para área de transferência');
      }
    );
  }

  return (
    <Modal
      visible={visible}
      onClickAway={() => handleClose()}
      effect="fadeInUp"
      width="400px"
      height="366px"
    >
      <Container onSubmit={formik.handleSubmit}>
        <header>Compartilhamento de carro</header>
        <div>
          <strong>Tempo de compartilhamento:</strong>
          <RadioContainer>
            <div>
              <input
                id="1"
                type="radio"
                value={1}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 1}
              />
              <label htmlFor="1">1 Hora</label>
            </div>
            <div>
              <input
                id="3"
                type="radio"
                value={3}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 3}
              />
              <label htmlFor="3">3 Horas</label>
            </div>
            <div>
              <input
                id="6"
                type="radio"
                value={6}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 6}
              />
              <label htmlFor="6">6 Horas</label>
            </div>
            <div>
              <input
                id="12"
                type="radio"
                value={12}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 12}
              />
              <label htmlFor="12">12 Horas</label>
            </div>
            <div>
              <input
                id="24"
                type="radio"
                value={24}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 24}
              />
              <label htmlFor="24">24 Horas</label>
            </div>
          </RadioContainer>
          <ShareUrl>
            <div>{url}</div>
            <button type="button" onClick={() => copyUrl()}>
              <MdContentCopy size="20" />
            </button>
          </ShareUrl>
        </div>
        <footer>
          <SimpleButton
            text="Fechar"
            bg="#E33939"
            color="#fff"
            onClick={() => handleClose()}
          />
        </footer>
      </Container>
    </Modal>
  );
}

ModalContent.propTypes = {
  tracker: PropTypes.objectOf.isRequired,
};
