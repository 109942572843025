import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { MdExitToApp } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import { Container, Count, RightContainer } from './styles';
import Logo from '~/assets/icons/negativeLogo.svg';
import { Creators as AuthActions } from '~/store/modules/auth/duck';

function Header() {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts.alerts);
  const loading = useSelector((state) => state.alerts.loading);

  function isIgnitionOnOff(alert) {
    return alert.flags.some(
      (i) => i === 'IGNIÇÃO DESLIGADA' || i === 'IGNIÇÃO LIGADA'
    );
  }

  return (
    <Container>
      <img src={Logo} alt="logo GEORASTREAMENTO" />
      <Count>
        {`
          ${
            alerts
              .flatMap((alert) => alert.trackers)
              .flatMap((alert) => alert.notifications)
              .filter((alert) => !isIgnitionOnOff(alert)).length
          }
            alertas não resolvidos`}
      </Count>
      <RightContainer>
        {loading && <FaSpinner className="icon-spin" size={20} color="#fff" />}
        <button type="button" onClick={() => dispatch(AuthActions.signOut())}>
          <MdExitToApp size={30} color="#fff" />
        </button>
      </RightContainer>
    </Container>
  );
}

export default Header;
