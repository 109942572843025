import React from 'react';
import ModalContainer from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, ButtonsContainer, Title, Descripition } from './styles';
import SimpleButton from '~/components/buttons/SimpleButton';
import { Creators as AlertsActions } from '~/store/modules/alerts/duck';

function ModalConfirm({ alert }) {
  const dispatch = useDispatch();
  const modalVisible = useSelector((state) => state.alerts.modalConfirm);

  function finishAlert() {
    dispatch(AlertsActions.alertFinish(alert));
  }
  return (
    <ModalContainer
      visible={modalVisible}
      effect="fadeInUp"
      onClickAway={() => dispatch(AlertsActions.setModalConfirm())}
      width="400px"
      height="366px"
    >
      <Container>
        <Title>FINALIZAR ALERTA</Title>
        <Descripition>
          <div>Tem certeza que deseja finalizar o alerta?</div>
        </Descripition>
        <ButtonsContainer>
          <SimpleButton
            text="Cancelar"
            bg="#E60000"
            color="#fff"
            onClick={() => dispatch(AlertsActions.setModalConfirm())}
          />
          <SimpleButton
            text="Finalizar"
            bg="#00456C"
            color="#fff"
            onClick={() => finishAlert()}
          />
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
}

ModalConfirm.propTypes = {
  alert: PropTypes.objectOf.isRequired,
};

export default ModalConfirm;
