import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import React from 'react';

import mapStyle from '~/assets/map.json';

import { initControls } from './controls';
import { Container } from './styles';

export default function GoogleMap({ children, loadMap, isShare }) {
  const createMapOptions = () => {
    return {
      panControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: false,
      streetViewControl: true,

      // scrollwheel: falses,
      styles: mapStyle,
    };
  };

  function finishLoading(map, maps) {
    initControls(map);
    loadMap(map, maps);
  }

  return (
    <Container isShare={isShare}>
      <GoogleMapReact
        onGoogleApiLoaded={({ map, maps }) => finishLoading(map, maps)}
        bootstrapURLKeys={{ key: 'AIzaSyApYh8zGAIB2GLkjEoAOkI3T3WoSz2jMHY' }}
        defaultCenter={{ lat: -20.361797, lng: -40.660631 }}
        defaultZoom={12}
        options={createMapOptions}
      >
        {children}
      </GoogleMapReact>
    </Container>
  );
}

GoogleMap.propTypes = {
  children: PropTypes.element.isRequired,
  loadMap: PropTypes.func.isRequired,
  isShare: PropTypes.bool,
};

GoogleMap.defaultProps = {
  isShare: false,
};
