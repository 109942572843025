import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import alerts from './alerts/sagas';
import buffer from './buffer/sagas';

export default function* rootSaga() {
  // yield take(REHYDRATE);
  return yield all([auth, alerts, buffer]);
}
