import trafficOff from '~/assets/map/trafficOff.png';
import trafficOn from '~/assets/map/trafficOn.png';
import widgetMinimap from '~/assets/map/widgetMinimap.svg';
import widgetStreetMap from '~/assets/map/widgetStreetMap.svg';

export function mapControl(controlDiv, map) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.border = '2px solid #fff';
  controlUI.style.marginRight = '11px';
  if (window.location.pathname.indexOf('detailCar') !== -1) {
    controlUI.style.marginBottom = '275px';
  } else if (window.location.pathname === '/daily') {
    controlUI.style.marginBottom = '185px';
  } else if (window.location.pathname.indexOf('share') !== -1) {
    controlUI.style.marginBottom = '220px';
  } else {
    controlUI.style.marginBottom = '44px';
  }
  controlUI.style.borderRadius = '10px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
  // controlUI.style.marginBottom = '10px';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '62px';
  controlUI.style.width = '70px';
  controlUI.title = 'Clique para mudar o tipo de mapa';
  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.style.height = '58px';
  controlImage.style.width = '66px';
  controlImage.style.borderRadius = '10px';
  controlImage.src = widgetMinimap;
  controlUI.appendChild(controlImage);

  const controlText = document.createElement('div');
  controlText.style.color = '#FFF';
  controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
  controlText.style.fontSize = '13px';
  controlText.style.lineHeight = '62px';
  controlText.style.paddingLeft = '5px';
  controlText.style.paddingRight = '5px';
  controlText.style.marginTop = '-42px';
  controlText.innerHTML = 'Mapa';
  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', function () {
    controlImage.src =
      map.mapTypeId === 'roadmap' ? widgetStreetMap : widgetMinimap;
    controlUI.style.borderColor = map.mapTypeId === 'roadmap' ? '#000' : '#fff';
    controlText.innerHTML = map.mapTypeId === 'roadmap' ? 'Satélite' : 'Mapa';
    controlText.style.color = map.mapTypeId === 'roadmap' ? '#000' : '#fff';
    map.mapTypeId === 'roadmap'
      ? map.setMapTypeId('hybrid')
      : map.setMapTypeId('roadmap');
  });
}

export function trafficControl(controlDiv, map) {
  const trafficLayer = new window.google.maps.TrafficLayer();
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '25px';
  controlUI.style.width = '25px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlUI.title = 'Clique para mostrar o trânsito';
  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.style.height = '25px';
  controlImage.style.padding = '2px 4px';
  controlImage.src = trafficOff;

  controlUI.appendChild(controlImage);

  controlUI.addEventListener('click', function () {
    if (trafficLayer.getMap() == null) {
      trafficLayer.setMap(map);
      controlImage.src = trafficOn;
    } else {
      trafficLayer.setMap(null);
      controlImage.src = trafficOff;
    }
  });
}
