import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { format, set } from 'date-fns';
import api from '~/services/api';
import { getAlerts } from './selectors';
import { Types } from './duck';
import beep from '~/util/beep';

function isIgnitionOnOff(alertValue) {
  return alertValue.flags.some(
    (i) => i === 'IGNIÇÃO DESLIGADA' || i === 'IGNIÇÃO LIGADA'
  );
}

export function* alert() {
  try {
    // debugger;
    const alertsData = yield select(getAlerts);
    const initialDate = format(new Date(), "yyyy-MM-dd'T00:00:00-0300");
    const finalDate = format(new Date(), "yyyy-MM-dd'T23:59:59-0300");
    const date = `initialDate=${initialDate}&finalDate=${finalDate}`;

    const { data: alerts } = yield call(
      api.get,
      `alerts?central=true&checked=false&${date}`
    );

    // console.log('store:modules:alerts:saga:alert', { alerts });

    // if (!(alertsData.filter((item) => !isIgnitionOnOff(item)).length === 0)) {
    //   if (
    //     alertsData.filter((item) => !isIgnitionOnOff(item)).length <
    //     alerts.filter((item) => !isIgnitionOnOff(item)).length
    //   ) {
    //     beep();
    //     toast.warn('Novo alerta no sistema!');
    //   }
    // }
    yield put({
      type: Types.ALERT_SUCCESS,
      alerts,
    });
  } catch (err) {
    yield put({
      type: Types.ALERT_FAILURE,
    });
  }
}
export function* finish({ alert: alertData }) {
  try {
    const isFinish = yield alertData.notifications.some(
      ({ checked }) => checked
    );
    yield call(api.put, `alerts`, alertData);

    if (isFinish) toast.success('Alerta finalizado com sucesso!');
    yield put({
      type: Types.ALERT_REQUEST,
    });
  } catch (err) {
    toast.success('Erro ao finalizar alerta!');
  }
}

export default all([
  takeLatest(Types.ALERT_REQUEST, alert),
  takeLatest(Types.ALERT_FINISH, finish),
]);
