import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 70px 1fr;
  grid-gap: 10px;
  grid-template-areas: 'header' 'body';
`;

export const Container = styled.div`
  grid-area: body;
`;
