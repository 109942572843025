import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  alertRequest: ['date'],
  alertSuccess: ['alerts'],
  alertFailure: [],
  alertFinish: ['alert'],
  setModalConfirm: [],
});

const INITIAL_STATE = {
  alerts: [],
  loading: false,
  loadingFinish: false,
  modalConfirm: false,
  countdown: 0,
};

const alertRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const alertSuccess = (state = INITIAL_STATE, { alerts }) => ({
  ...state,
  alerts,
  loading: false,
  loadingFinish: false,
  modalConfirm: false,
});

const alertFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  loadingFinish: false,
  alerts: [],
  modalConfirm: false,
});

const alertFinish = (state = INITIAL_STATE) => ({
  ...state,
  loadingFinish: true,
});

const setModalConfirm = (state = INITIAL_STATE) => ({
  ...state,
  modalConfirm: !state.modalConfirm,
});

export default createReducer(INITIAL_STATE, {
  [Types.ALERT_REQUEST]: alertRequest,
  [Types.ALERT_SUCCESS]: alertSuccess,
  [Types.ALERT_FAILURE]: alertFailure,
  [Types.ALERT_FINISH]: alertFinish,
  [Types.SET_MODAL_CONFIRM]: setModalConfirm,
});
