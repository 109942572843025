import { toast } from 'react-toastify';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';

import { Types } from './duck';

export function* signIn(payload) {
  try {
    const { cpf, password } = payload;

    const response = yield call(api.post, 'auth/login', {
      cpf,
      password,
      isApp: true,
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `${token}`;

    yield put({
      type: Types.SIGN_IN_SUCCESS,
      payload: { token, user },
    });

    history.push('/home');
  } catch (err) {
    toast.error(err.response.data.error);
    yield put({
      type: Types.SIGN_FAILURE,
    });
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;
  api.defaults.headers.Authorization = `${token}`;
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(Types.SIGN_IN_REQUEST, signIn),
]);
