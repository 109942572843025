import styled from 'styled-components';

export const Container = styled.div`
  grid-area: header;
  width: 100%;
  height: 70px;
  background: #00456c;
  box-shadow: 0px 4px 4px rgba(0, 69, 108, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  > img {
    height: 50px;
    margin-left: 10px;
  }
`;

export const Count = styled.div`
  margin-left: 10px;
  color: #fff;
  font-size: 20px;
`;

export const RightContainer = styled.div`
  > button {
    background: transparent;
    border: none;
    margin: 0 10px 0 10px;
  }
  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;
