import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  height: calc(100vh - 80px);
`;

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.isTracker ? '1fr 1fr' : '1fr')};
  grid-gap: 10px;
`;

export const List = styled.div`
  position: relative;
  display: flex;
  height: ${(props) => props.height || '195px'};
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 69, 108, 0.25);
  border-radius: 15px;
  flex-direction: column;
  margin-top: 10px;
  :first-child {
    margin-top: 0;
  }
  > header {
    border-radius: 15px 15px 0 0;
    height: 50px;
    background: #00456c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  > header.ignitiononoff {
    border-radius: 0 0 0 0;
    height: 50px;
    background: #00456c;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
  }
  > header.ignitiononoff div strong {
    font-size: large;
  }
  > header.ignitiononoff.red {
    background: red;
  }
  > header.ignitiononoff.green {
    background: green;
  }
  > footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5px;
  }
`;

export const ClientContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  > strong {
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
  }
  > small {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 23px;

    color: #bde7ff;
  }
`;
export const ActionContainer = styled.div`
  > button {
    background: transparent;
    border: none;
    margin: 0 5px;
    > img {
      height: 35px;
    }
    transition: background 0.2s;
    :hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;

export const SubmitContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 70px;
  > textarea {
    resize: none;
    border: none;
    border-radius: 10px 0 0 10px;
    background: #f4fbff;
    box-shadow: 0px 4px 4px rgba(0, 69, 108, 0.13);
    padding: 5px;
    color: #2086bf;
    ::placeholder {
      color: #2086bf;
    }
  }
`;

export const DetailContainer = styled.div`
  > div {
    color: #00456c;
    display: flex;
    > div {
      font-weight: 600;
      font-size: 12px;
      line-height: 23px;
      width: 60px;
    }
    > span {
      font-weight: 300;
      font-size: 12px;
      line-height: 23px;
    }
  }
`;

export const Badge = styled.div`
  position: absolute;
  right: 0;
  background: #e60000;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  border-radius: 500px;
  margin-top: -10px;
  height: 20px;
`;
