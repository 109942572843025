import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 69, 108, 0.25);
  border-radius: 20px;
`;

const pulsing = keyframes`
0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0 ,0 , 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(255, 0 ,0 , 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 0 ,0 , 0);
  }
`;

export const Marker = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  > svg {
    position: absolute;
    margin-top: -40px;
    margin-left: 30px;
  }
  img {
    height: 30px;
    width: 30px;
    opacity: ${(props) => (props.alert === 'DISCONNECTED' ? 0.5 : 1)};
    transform: ${(props) => props.rotate && `rotate(${props.rotate}deg)`};
  }

  > div {
    height: 17px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-left: -15px;
    margin-top: -25px;
    position: absolute;
    color: #fff;
    font-weight: 400;
    background: ${(props) => {
      if (props.alert === 'DISCONNECTED') {
        return 'rgba(0, 0, 0, 0.2)';
      }
      if (props.alert === 'ALERT') {
        return 'rgba(255, 20,0, 0.41)';
      }
      return 'rgba(0, 0, 0, 0.41)';
    }};

    ${(props) =>
      props.alert === 'ALERT' &&
      css`
        animation: 2s ${pulsing} infinite;
      `}
  }
`;
