import { darken } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AnimationLoading from './animationLoading';

const SimpleButton = styled.button.attrs((props) => ({
  type: props.type ? props.type : 'button',
}))`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '40px')};
  border: none;
  padding: 0 10px;
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  background: ${(props) => (props.bg ? props.bg : '#fff')};
  color: ${(props) => (props.color ? props.color : '#333')};
  :hover {
    background: ${(props) =>
      props.bg ? darken(0.03, props.bg) : darken(0.03, '#fff')};
  }

  cursor: ${(props) => {
    if (props.disabled) {
      return 'not-allowed';
    }
    if (props.loading) {
      return 'progress';
    }
    return 'pointer';
  }};
  :active {
    opacity: 0.5;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  grid-area: ${(props) => props.area && props.area};
`;

function Button({ text, area, loading, ...props }) {
  return (
    <ButtonContainer area={area}>
      <SimpleButton loading={loading} {...props}>
        {loading ? <AnimationLoading /> : text}
      </SimpleButton>
    </ButtonContainer>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  area: PropTypes.string,
};

Button.defaultProps = {
  loading: false,
  area: '',
};

export default Button;
