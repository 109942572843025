// import { Alert } from 'react-native';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from './duck';

export function* trackers({ id }) {
  try {
    const { data: tracker } = yield call(api.get, `/buffer?tracker_id=${id}`);

    yield put({
      type: Types.BUFFER_SUCCESS,
      tracker: tracker[0],
    });
  } catch (error) {
    // Alert.alert(
    //   'Falha na conexão',
    //   'Confira a sua conexão e reinice o aplicativo'
    // );

    yield put({
      type: Types.BUFFER_FAILURE,
    });
  }
}

export default all([takeLatest(Types.BUFFER_REQUEST, trackers)]);
