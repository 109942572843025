import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Container,
  List,
  SubmitContainer,
  DetailContainer,
  Badge,
  ActionContainer,
  ClientContainer,
  Wrap,
} from './styles';
import { Creators as AlertsActions } from '~/store/modules/alerts/duck';
import { Creators as BufferActions } from '~/store/modules/buffer/duck';
import IconButton from '~/components/buttons/IconButton';
import check from '~/assets/icons/check.svg';
import whatsapp from '~/assets/icons/whatsapp.svg';
// import directions from '~/assets/icons/directions.svg';
import map from '~/assets/icons/map.svg';
import mapColor from '~/assets/icons/mapColor.svg';
import share from '~/assets/icons/share.svg';
import ModalConfirm from './ModalConfirm';
import ModalShare from './ModalShare';
import Map from './Map';

export default function SignIn() {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts.alerts);
  // const [alerts] = useState([
  //   {
  //     status: 'Cooperativa Agropecuária Centro Serrana',
  //     trackers: [
  //       {
  //         tracker_id: 'PPD-9871',
  //         notifications: [
  //           {
  //             plate: 'PPD-9871',
  //             brand: 'Fiat',
  //             model: 'Strada Working',
  //             year: '2015/2015',
  //             color: 'Branco',
  //             date: '2022-05-05T22:54:16.181Z',
  //             tracker_id: 1255,
  //             client: 'Cooperativa Agropecuária Centro Serrana',
  //             phone: '(27) 9.9824-5631',
  //             flags: ['Direção fora do horário'],
  //             checked: false,
  //             user_id: null,
  //             notification_id: 1116223,
  //             procedure: null,
  //             phone_emergency: '(27)9 9824-5631',
  //             contact_emergency: 'diarlei',
  //             email_emergency: 'diarlei.nitz@coopeavi.coop.br',
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ]);
  const loadingFinish = useSelector((state) => state.alerts.loadingFinish);
  const tracker_id = useSelector((state) => state.buffer.tracker_id);
  const [data, setData] = useState([]);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [alertData, setAlertData] = useState({});
  const [tracker, setTracker] = useState({});
  const [countdown, setCountdown] = useState(0);
  const copiaecolainfo = React.createRef();

  function updateFunction(alert, procedure) {
    const query = {
      notifications: [{ ...alert }],
      procedure,
    };
    dispatch(AlertsActions.alertFinish(query));
  }

  const [updateDebouce] = useState(() => _.debounce(updateFunction, 2000));

  useEffect(() => {
    // console.log('useEffect:dispatch');
    dispatch(AlertsActions.alertRequest());
    dispatch(BufferActions.setTrackerId(null));
  }, [dispatch]);

  useEffect(() => {
    // console.log('useEffect:autoRefresh,dispatch');

    const interval = setInterval(() => {
      if (autoRefresh) {
        dispatch(AlertsActions.alertRequest());
      } else {
        console.info(
          'O campo está sendo preenchido por conta disso não ouve o auto refresh'
        );
      }
    }, 20000);

    return () => {
      if (interval) {
        // console.log('useEffect:autoRefresh:return');
        clearInterval(interval);
      }
    };
  }, [autoRefresh, dispatch]);

  useEffect(() => {
    // console.log('useEffect:alerts:begin');
    const _data = alerts
      .flatMap((alert) => alert.trackers)
      .flatMap((alert) => alert.notifications)
      .sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)));
    setData(_data);
    // console.log('useEffect:alerts:end', { alerts, data: _data });
    // setAutoRefresh(true);
  }, [alerts]);

  function finishAlert(alertDataQuery) {
    const query = {
      notifications: [{ ...alertDataQuery, checked: true }],
      procedure: alertDataQuery.procedure,
    };
    if (
      alertDataQuery.procedure === '' ||
      alertDataQuery.procedure === undefined ||
      !alertDataQuery.procedure
    ) {
      toast.warn('O campo de descrição é obrigatório');
    } else {
      setAlertData(query);
      dispatch(AlertsActions.setModalConfirm());
      setTimeout(() => {
        setAutoRefresh(true);
      }, 3500);
    }
  }

  function viewMap(id) {
    if (id) {
      dispatch(BufferActions.bufferRequest(id));
    }
    dispatch(BufferActions.setTrackerId(id));
  }

  /*
  useEffect(() => {
    // console.log('useEffect:tracker_id');
    if (tracker_id) {
      const interval = setInterval(() => {
        dispatch(BufferActions.bufferRequest(tracker_id));
      }, 30000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [tracker_id]);
  */

  function viewModalShare(alert) {
    setTracker(alert);
    dispatch(BufferActions.setModalShare());
  }

  function isIgnitionOnOff(alert) {
    return alert.flags.some(
      (i) => i === 'IGNIÇÃO DESLIGADA' || i === 'IGNIÇÃO LIGADA'
    );
  }

  return (
    <Wrap isTracker={tracker_id}>
      <textarea
        ref={copiaecolainfo}
        style={{
          display: 'block',
          overflow: 'none',
          border: 'none',
          height: 2,
          color: 'white',
        }}
      />
      <PerfectScrollbar>
        <Container>
          {_.isEmpty(data)
            ? 'Não possui dados'
            : data
                .filter((alert) => !isIgnitionOnOff(alert))
                .map((alert, index) => (
                  <List key={alert.notification_id}>
                    <>
                      <Badge>
                        {differenceInMinutes(new Date(), new Date(alert.date))}{' '}
                        min
                      </Badge>
                      <header>
                        <ClientContainer>
                          <strong>{alert.client}</strong>
                          <small>
                            {alert.phone_emergency
                              ? `${alert.contact_emergency}: ${alert.phone_emergency}`
                              : 'Nenhum contato de emergência cadastrado'}
                          </small>
                        </ClientContainer>
                        <ActionContainer>
                          {tracker_id && (
                            <button
                              type="button"
                              onClick={() => viewModalShare(alert)}
                            >
                              <img src={share} alt="Compartilhar" />
                            </button>
                          )}
                          {alert.phone_emergency && (
                            <button
                              type="button"
                              title="clique para informar esta ocorrência, ao gestor de frotas, via whatsapp."
                              onClick={() => {
                                setAutoRefresh(false);
                                // console.log('setAutoRefresh(false);');
                                // TODO copiar info para area de transferencia
                                try {
                                  copiaecolainfo.current.value = `CENTRAL DE MONITORAMENTO 🚨
———————————————
${new Date(alert.date).toLocaleString()}

*${(alert.plate || '').toUpperCase()}*
\`\`\`${(alert.brand || '').toUpperCase()} ${(
                                    alert.model || ''
                                  ).toUpperCase()} ${(
                                    alert.year || ''
                                  ).toUpperCase()}
${(alert.color || '').toUpperCase()}\`\`\`

${(alert.flags || [])
  .map((flag) => `*${(flag || '').toUpperCase()}*`)
  .join('\n')}

*LOCALIZAÇÃO*
${(alert.position || {}).lat}, ${(alert.position || {}).lng}
${(alert.position || {}).address}

*MAPA*
https://www.google.com/maps/place/${(alert.position || {}).lat},${
                                    (alert.position || {}).lng
                                  }/@${(alert.position || {}).lat},${
                                    (alert.position || {}).lng
                                  },16z/

*SISTEMA*
https://pwa.geomobilidade.com.br/detailCar/${alert.tracker_id}
`;
                                  copiaecolainfo.current.focus();
                                  copiaecolainfo.current.focus();
                                  copiaecolainfo.current.focus();
                                  copiaecolainfo.current.select();
                                  copiaecolainfo.current.select();
                                  copiaecolainfo.current.select();
                                  document.execCommand('copy');
                                  document.execCommand('copy');
                                  document.execCommand('copy');
                                  // console.log(alert);
                                } catch (err) {
                                  // console.log('alert', alert);
                                  console.log(
                                    'navigator.clipboard',
                                    err || err.message || err
                                  );
                                }
                                const procedure = `${alert.flags
                                  .map((flag) => (flag || '').toUpperCase())
                                  .join(',')}\nInformado ao gestor de frotas ${(
                                  alert.contact_emergency || ''
                                ).toUpperCase()} via whatsapp de número ${
                                  alert.phone_emergency
                                }\n`;
                                window.open(
                                  `https://api.whatsapp.com/send?phone=55${alert.phone_emergency.replace(
                                    /[\D]+/g,
                                    ''
                                  )}`
                                );
                                updateDebouce(alert, procedure);
                              }}
                            >
                              <img
                                src={whatsapp}
                                alt="Conversa no whatsapp com contato de emergência"
                              />
                            </button>
                          )}

                          <button
                            type="button"
                            onClick={() =>
                              viewMap(
                                alert.tracker_id === tracker_id
                                  ? null
                                  : alert.tracker_id
                              )
                            }
                          >
                            <img
                              src={
                                tracker_id === alert.tracker_id ? mapColor : map
                              }
                              alt="Ver veículo no mapa"
                            />
                          </button>
                        </ActionContainer>
                      </header>
                      <footer>
                        <DetailContainer>
                          <div>
                            <div>Placa:</div>
                            <strong>{alert.plate}</strong>
                          </div>
                          <div>
                            <div>Momento:</div>
                            <span>{new Date(alert.date).toLocaleString()}</span>
                          </div>
                          <div>
                            <div>Alerta(s):</div>
                            <strong>
                              {alert.flags
                                .map((flag) => (flag || '').toUpperCase())
                                .join(', ')}
                            </strong>
                          </div>
                          <div>
                            <div>Veículo:</div>
                            <span>
                              {alert.brand} - {alert.model} - {alert.color} -{' '}
                              {alert.year}
                            </span>
                          </div>
                          <div>
                            <div>Endereço:</div>
                            <span>{alert.position.address}</span>
                          </div>
                          <div>
                            <div>Protocolo:</div>
                            <span>{`${alert.notification_id}/${format(
                              new Date(alert.date),
                              'yyyy'
                            )}`}</span>
                          </div>
                        </DetailContainer>
                        <SubmitContainer>
                          <textarea
                            placeholder="Informe a descrição da ação tomada nessa ocorrência"
                            onChange={({ target: { value } }) => {
                              if (value !== alert.procedure) {
                                setAutoRefresh(false);
                                alert.procedure = value;
                                setData((dataArray) => {
                                  const obj = dataArray.find(
                                    ({ notification_id }) =>
                                      Number(notification_id) ===
                                      Number(alert.notification_id)
                                  );
                                  obj.procedure = value;
                                  return [...dataArray];
                                });
                                updateDebouce(alert, value);
                              } else {
                                setAutoRefresh(true);
                              }
                            }}
                            value={alert.procedure || undefined}
                            autoFocus={index === 0}
                          />
                          <IconButton
                            type="button"
                            icon={check}
                            bg="#245378"
                            radius="0 10px 10px 0"
                            color="#fff"
                            height="100%"
                            onClick={() => finishAlert(alert)}
                            loading={loadingFinish}
                          />
                        </SubmitContainer>
                      </footer>
                    </>
                  </List>
                ))}
          {/* {!_.isEmpty(data) &&
            data
              .filter((alert) => isIgnitionOnOff(alert))
              .map((alert) => (
                <List height="33px">
                  <header
                    className={`ignitiononoff ${
                      alert.flags.includes('IGNIÇÃO LIGADA') ? 'green' : 'red'
                    }`}
                  >
                    <ClientContainer flexDirection="row">
                      <strong
                        style={{
                          marginRight: '10px',
                          width: '90px',
                          minWidth: '90px',
                          maxWidth: '90px',
                          cursor: 'context-menu',
                        }}
                        title={`${alert.brand} - ${alert.model} - ${alert.color} - ${alert.year}`}
                      >
                        {alert.plate}
                      </strong>
                      <strong
                        style={{
                          marginRight: '20px',
                          width: '190px',
                          minWidth: '190px',
                          maxWidth: '190px',
                        }}
                      >
                        {alert.flags
                          .map((flag) => flag.toUpperCase())
                          .join(', ')}
                      </strong>
                      <strong
                        style={{
                          fontSize: 'small',
                          marginRight: '20px',
                          width: '500px',
                          minWidth: '500px',
                          maxWidth: '500px',
                        }}
                      >
                        {alert.position.address}
                      </strong>
                      <strong
                        style={{
                          display: 'block',
                          color: 'white',
                          fontSize: 'larger',
                        }}
                      >
                        {alert.client.toUpperCase()}
                      </strong>
                    </ClientContainer>
                    <strong style={{ marginRight: '10px', color: 'white' }}>
                      {new Date(alert.date).toLocaleString()}
                    </strong>
                  </header>
                </List>
              ))} */}
        </Container>
      </PerfectScrollbar>
      {/* {tracker_id && <Map />} */}
      <ModalConfirm alert={alertData} />
      <ModalShare tracker={tracker} />
    </Wrap>
  );
}
