import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  > div {
    > div {
      border-radius: 10px;
    }
  }
`;
