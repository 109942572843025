import styled from 'styled-components';

export const Container = styled.div`
  background: #245378;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.form`
  width: 100%;
  height: 400px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  max-width: 500px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > header {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
  }
`;
