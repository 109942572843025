import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  bufferRequest: ['id'],
  bufferSuccess: ['tracker'],
  bufferFailure: [],
  setTrackerId: ['id'],
  setModalShare: [],
});

const INITIAL_STATE = {
  tracker: {},
  loading: false,
  tracker_id: null,
  modalShare: false,
};

const bufferRequest = (state = INITIAL_STATE, { id: tracker_id }) => ({
  ...state,
  loading: true,
  tracker_id,
});

const bufferSuccess = (state = INITIAL_STATE, { tracker }) => ({
  ...state,
  tracker,
  loading: false,
});

const bufferFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  tracker_id: null,
});
const setTrackerId = (state = INITIAL_STATE, { id: tracker_id }) => ({
  ...state,
  tracker_id,
});
const setModalShare = (state = INITIAL_STATE) => ({
  ...state,
  modalShare: !state.modalShare,
});

export default createReducer(INITIAL_STATE, {
  [Types.BUFFER_REQUEST]: bufferRequest,
  [Types.BUFFER_SUCCESS]: bufferSuccess,
  [Types.BUFFER_FAILURE]: bufferFailure,
  [Types.SET_TRACKER_ID]: setTrackerId,
  [Types.SET_MODAL_SHARE]: setModalShare,
});
